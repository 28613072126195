'use client';
import React, { useState } from 'react';
import Image from 'next/image';
import SupportBubble from '../supportBubble';
import { FloatButton } from 'antd';

function SupportButton() {
  const [visible, setVisible] = useState(false); // State to control the visibility of the drawer

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <>
      <FloatButton
        shape="circle"
        type="primary"
        className="animate-bounce"
        onClick={toggleVisibility}
        icon={
          <div className="flex h-[25px] w-[25px] items-center justify-center">
            <Image
              src={
                visible
                  ? '/icons/close-support-bubble.svg'
                  : '/icons/support-bubble.svg'
              }
              alt="support-bubble"
              style={{ objectFit: 'cover' }}
              fill={true}
            />
          </div>
        }
      />

      <SupportBubble
        visible={visible}
        closeDrawer={toggleVisibility}
        position=""
      />
    </>
  );
}

export default SupportButton;
