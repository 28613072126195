import appRoutes from '@/constants/routes';
import { Link } from '@/intl/navigation';
import Image from 'next/image';

type BrandNameProps = {
  isFooter?: boolean;
};
export default function BrandName({ isFooter = false }: BrandNameProps) {
  return (
    <>
      {isFooter ? (
        <Link href={appRoutes.home}>
          <Image
            src="/logos/afiwa-exchange-white-logo.png"
            width={257}
            height={60}
            alt="afiwa-logo"
          />
        </Link>
      ) : (
        <>
          <Link
            className="relative h-[40px] min-w-[180px]  md:min-w-[220px]"
            href={appRoutes.home}
          >
            <Image
              src="/logos/afiwa-exchange.png"
              style={{ objectFit: 'cover' }}
              fill={true}
              alt="afiwa-logo"
            />
          </Link>
        </>
      )}
    </>
  );
}
