'use client';
import React, { useState, useRef } from 'react';
import { Link } from '@/intl/navigation';
import { useTranslations } from 'next-intl';
import BrandName from '@/ui/shared/components/brandName';
import LanguageSelector from '@/ui/shared/components/languageSelector';
import appRoutes from '@/constants/routes';
import { usePathname } from '@/intl/navigation';
import { Drawer } from 'antd';
import AfiwaButton from '@/ui/shared/components/afiwaButton';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import BurgerMenu from '../burgerMenu';
import { useSession } from 'next-auth/react';
import UserAvatarName from '@/ui/shared/components/userAvatarName';

type PublicUserAvatarNameProps = {
  openDropdown: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  userName?: string;
  navLinks: { name: string; href: string }[];
};

function PublicNavBar() {
  //Hook to get translation functions for the 'navigation' namespace
  const t = useTranslations('navigation');
  const appText = useTranslations('inApp');
  const pathname = usePathname(); // Hook to get the current pathname
  const [visible, setVisible] = useState(false); // State to control the visibility of the drawer
  const [openUserDropdown, setOpenUserDropdown] = useState<boolean>(false); // State to track if the User Avatar  dropdown menu is open
  const dropdownTimeout = useRef<NodeJS.Timeout | null>(null); // Ref to hold the timeout for closing the dropdown
  const { data: session } = useSession();

  // NAVLINKS USER
  const userNavLinks = [
    { name: appText('dahboard'), href: appRoutes.users },
    { name: t('exchange'), href: appRoutes.users_exchange },
    { name: t('transactions'), href: appRoutes.users_transactions },
    { name: t('profile'), href: appRoutes.users_profile },
    { name: t('affiliate_program'), href: appRoutes.affiliate_program },
    // { name: t('events'), href: appRoutes.events },
  ];
  // NAVLINKS ADMIN
  const adminNavLinks = [
    {
      name: appText('dahboard'),
      href: appRoutes.admin_dashboard,
    },
    {
      name: t('transactions'),
      href: appRoutes.admin_manage_transactions,
    },
    {
      name: t('assets'),
      href: appRoutes.admin_manage_assets,
    },
    {
      name: t('customers'),
      href: appRoutes.admin_manage_customers,
    },
    {
      name: t('managers'),
      href: appRoutes.admin_managers,
    },
    {
      name: t('affiliate_program'),
      href: appRoutes.admin_manage_affiliates,
    },
    {
      name: t('profile'),
      href: appRoutes.admin_profile,
    },
  ];

  // Function to  toggle navigation-drawer
  const toggleNavDrawer = () => {
    setVisible(!visible);
  };

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    if (dropdownTimeout.current) {
      clearTimeout(dropdownTimeout.current); // Clear the timeout if it exists
    }
    setOpenUserDropdown(true); // Open the dropdown
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    dropdownTimeout.current = setTimeout(() => {
      setOpenUserDropdown(false); // Close the dropdown after 200ms
    }, 300);
  };

  //flex items-center justify-between  gap-0 py-[16px] text-center text-base font-medium leading-[12.69px] tracking-[0.001em] text-primary-pale   md:py-[19px] md:text-sm md:leading-[28px]  lg:gap-[10px] lg:text-base  xl:gap-[80px]
  return (
    <>
      <div className="fixed top-0 z-50 h-[70px] w-full bg-slate-100">
        <div className="app-container">
          <nav className="flex items-center py-3">
            {/* Brand Name */}
            <div className="flex items-center">
              <BrandName />
            </div>

            {/* Navigation Links for Desktop */}
            <div className="ml-auto flex items-center">
              {/* hiden for all for screens < mediumn (md) */}
              <div className="hidden items-center gap-5 xl:flex">
                {/* about-us  */}
                <Link
                  className={`${
                    pathname.slice(
                      pathname.length - appRoutes.about_us.length,
                    ) === appRoutes.about_us
                      ? 'active-nav-link'
                      : 'nav-link-hover'
                  } nav-link`}
                  href={appRoutes.about_us}
                >
                  {t('about_us')}
                </Link>

                {/* tutorials */}
                <Link
                  className={`${
                    pathname.slice(
                      pathname.length - appRoutes.tutorials.length,
                    ) === appRoutes.tutorials
                      ? 'active-nav-link'
                      : 'nav-link-hover'
                  } nav-link`}
                  href={appRoutes.tutorials}
                >
                  {t('tutorial')}
                </Link>
                {/* affiliation */}
                <Link
                  className={`${
                    pathname.slice(
                      pathname.length - appRoutes.affiliate_program.length,
                    ) === appRoutes.affiliate_program
                      ? 'active-nav-link'
                      : 'nav-link-hover'
                  } nav-link`}
                  href={appRoutes.affiliate_program}
                >
                  {t('affiliate_program')}
                </Link>

                {/* events */}
                {/* <Link
                  className={`${
                    pathname.slice(
                      pathname.length - appRoutes.events.length,
                    ) === appRoutes.events
                      ? 'active-nav-link'
                      : 'nav-link-hover'
                  } nav-link`}
                  href={appRoutes.events}
                >
                  {t('events')}
                </Link> */}

                {/* contact-us // hiden all for screens < large (lg) */}
                <Link
                  className={`hidden xl:block ${
                    pathname.slice(
                      pathname.length - appRoutes.contact_us.length,
                    ) === appRoutes.contact_us
                      ? 'active-nav-link'
                      : 'nav-link-hover'
                  } nav-link mx-2 `}
                  href={appRoutes.contact_us}
                >
                  {t('contact_us')}
                </Link>
              </div>

              {/* auth-actions-btn */}
              <div className="ms-10 hidden items-center gap-3 md:flex ">
                {!session?.user &&
                  pathname !== appRoutes.signup &&
                  pathname !== appRoutes.login && (
                    <>
                      <AfiwaButton
                        size="large"
                        href={appRoutes.login}
                        text={t('login')}
                      />
                      <AfiwaButton
                        type="primary"
                        size="large"
                        href={appRoutes.signup}
                        text={t('signup')}
                        rightIcon={
                          <ArrowRightIcon
                            className={'hidden md:block'}
                            height={15}
                            width={15}
                          />
                        }
                      />
                    </>
                  )}
              </div>

              {/* User Avatar */}
              {session?.user?.name && (
                <div className="mx-5  md:mx-2">
                  <PublicUserAvatarName
                    openDropdown={openUserDropdown}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    userName={session?.user.name}
                    navLinks={
                      session?.user.role === 'ADMIN'
                        ? adminNavLinks
                        : userNavLinks
                    }
                  />
                </div>
              )}

              {/* Language Selector and burger menu */}
              <div className="flex items-center justify-center gap-5 md:gap-3">
                <LanguageSelector />
                {/* hidden on screes > xl (extra-large) */}
                <BurgerMenu
                  onClick={toggleNavDrawer}
                  className="!inline-flex xl:!hidden"
                />
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* navigation-drawer */}
      {/* Drawer for Mobile Navigation */}
      <Drawer
        placement="bottom"
        closable={true}
        onClose={toggleNavDrawer}
        open={visible}
        // width="100%"
        height="85%"
        styles={{
          content: {
            background: '#FFFFFF',
          },
        }}
      >
        <div className="flex flex-col  font-semibold">
          {!session?.user?.name && (
            <>
              {/* login */}
              <Link
                onClick={toggleNavDrawer}
                href={appRoutes.signup}
                className="flex border-b-[0.5px] border-gray-200 py-5 text-primary-pale md:hidden"
              >
                {t('signup')}
              </Link>
              {/* signup */}
              <Link
                onClick={toggleNavDrawer}
                href={appRoutes.login}
                className="flex border-b-[0.5px] border-gray-200 py-5 text-primary-pale md:hidden"
              >
                {t('login')}
              </Link>
            </>
          )}
          {/* affiliate */}
          <Link
            onClick={toggleNavDrawer}
            href={appRoutes.affiliate_program}
            className="border-b-[0.5px] border-gray-200 py-5 text-primary-pale"
          >
            {t('affiliate_program')}
          </Link>
          {/* tutorials */}
          <Link
            onClick={toggleNavDrawer}
            href={appRoutes.tutorials}
            className="border-b-[0.5px] border-gray-200 py-5 text-primary-pale"
          >
            {t('tutorial')}
          </Link>
          {/* aboutus */}
          <Link
            onClick={toggleNavDrawer}
            href={appRoutes.about_us}
            className="border-b-[0.5px] border-gray-200 py-5 text-primary-pale"
          >
            {t('about_us')}
          </Link>
          {/* contactus */}
          <Link
            onClick={toggleNavDrawer}
            href={appRoutes.contact_us}
            className="border-b-[0.5px] border-gray-200 py-5 text-primary-pale"
          >
            {t('contact_us')}
          </Link>
          {/* home */}
          <Link
            onClick={toggleNavDrawer}
            href={appRoutes.home}
            className="mb-5 border-b-[0.5px] border-gray-200 py-5 text-primary-pale"
          >
            {t('home')}
          </Link>
        </div>
      </Drawer>
    </>
  );
}

export default PublicNavBar;

function PublicUserAvatarName({
  openDropdown,
  handleMouseEnter,
  handleMouseLeave,
  userName,
  navLinks,
}: PublicUserAvatarNameProps) {
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative"
    >
      {/* User Avatar */}
      {userName && (
        <>
          <div className="inline-flex sm:hidden">
            <UserAvatarName userName={''} color="text-primary-pale" />
          </div>
          <div className="hidden sm:inline-flex">
            <UserAvatarName userName={userName} color="text-primary-pale" />
          </div>
        </>
      )}

      {/* Dropdown Menu */}
      {openDropdown && (
        <div
          className="absolute -left-10 z-10 mt-3  w-44 divide-y divide-gray-100 rounded-lg bg-white shadow-lg"
          role="menu"
          aria-labelledby="user-menu-button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ul className="py-2 text-sm text-primary-pale">
            {navLinks.map((navLink) => (
              <li key={navLink.name}>
                <Link
                  href={navLink.href}
                  className="block px-4 py-2 hover:bg-primary hover:font-bold hover:text-white"
                  role="menuitem"
                >
                  {navLink.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
